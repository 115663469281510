

.text-primary{color:#007bff!important}
.text-secondary{color:#6c757d!important}
.text-success{color:#28a745!important}
.text-danger{color:#dc3545!important}
.text-warning{color:#ffc107!important}
.text-info{color:#17a2b8!important}
.text-light{color:#f8f9fa!important}
.text-dark{color:#343a40!important}
.text-white{color:#ffffff!important}

.bg-primary{ background-color:#007bff!important}
.bg-secondary{ background-color:#6c757d!important}
.bg-success{ background-color:#28a745!important}
.bg-danger{ background-color:#dc3545!important}
.bg-warning{ background-color:#ffc107!important}
.bg-info{ background-color:#17a2b8!important}
.bg-light{ background-color:#f8f9fa!important}
.bg-dark{ background-color:#343a40!important}
.bg-white{background-color:#ffffff!important}

.btn-primary{ color:#ffffff; background-color:#007bff!important}
.btn-secondary{ color:#ffffff; background-color:#6c757d!important}
.btn-success{ color:#ffffff; background-color:#28a745!important}
.btn-danger{ color:#ffffff; background-color:#dc3545!important}
.btn-warning{ color:#ffffff; background-color:#ffc107!important}
.btn-info{ color:#ffffff; background-color:#17a2b8!important}
.btn-light{ color:#343a40; background-color:#f8f9fa!important}
.btn-dark{ color:#ffffff; background-color:#343a40!important}
.btn-white{color:#343a40; background-color:#ffffff!important}

span.click{
  cursor: pointer;
  transition: ease-in-out 500ms;

}

span.click:hover{
  transform: scale(1.1);
}

button {
  padding: .3em 1.3em;
  border-radius: 4px;
  border:none;
  font-size: 1.2em;
}

button:focus, input:focus, select:focus{
  outline: none;
    box-shadow: 2px 2px 5px #aaa;
}

.form-input input,.form-input select, .form-input.button button {
  padding:.3em 1em;
  border-radius: .25em;  
  font-size: 1.2em;
  border:solid 2px #eeeeee;
}

.form-input{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.form-input.button {
  margin-bottom: 1em;
  display:flex;
  justify-content: flex-end;
}


.sticky{
  position: sticky;
  top:0;
  background-color: #fff;
  z-index: 900;
}

.logo{
  align-items: center;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}


.error{
  padding: 1em;
  color:#ffffff;
  background-color: #f56c6c;
  border: solid 1px #D56c6c;
  border-radius: .2em;
  margin: .5em 0;
}

.screen{
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-color: rgba(80,80,80, .5);
  display:flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}




.editor-class{
  min-height: 400px;
  border:1px solid #eee;
  border-radius: 3px;
  margin-bottom:1em;
  padding:0 1em;
}



.modal-close{
  position: absolute;
  right:.3em;
  top:.3em;
}

span.close{
  border-radius: 99px;
  border: solid 1px #aaa;
  padding:4px 8px;
  background-color: #EEE;
}

span.close:hover{
  cursor: pointer;
  border-width: 2px;
  box-shadow: 2px 2px 5px #aaa;
}

.footer{
  position: absolute;
  bottom:0;
  right:0;
  left:0;
}

.footer img{
  max-height: 45px;
}

.container{
  background-color:#222;
  background: radial-gradient(#444, #000);
  position: fixed;
  top:0;right:0;left:0;bottom:0;
  padding-left:0 !important;
  padding-right:0 !important;  
  overflow: auto;
}

.container .body{
  margin-bottom:60px;
}


.navbar-collapse {
  padding-right: 0 !important;
  padding-left: 0 !important;
}


button.btn .caret{
  margin-left:.5em;
}

.btn {margin-right:1em;}


.editor{
  margin-right:1em;
}

.main-logo {
  max-width: 250px;
}

.footer{
  justify-content: flex-end;
  display:flex;
  padding:0 1em;
}

.landing-page-container{
  margin:4em 1em;
  /* position: absolute;
  top:0;right:0;left:0;bottom:0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-options{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  margin:1em;  
}

.landing-page-options .option{
  background-color: #ddd;
  margin:1em;
  padding:1em;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 350px;
  transition: ease-in-out 2s;
  position: relative;
  cursor: pointer;
  box-shadow: 5px 5px 12px #aaa;
}

.landing-page-options .option:hover{
  transition: ease-in-out .5s;
  transform: scale(1.1);
}

.landing-page-options .option .title{
  font-size: 2em;
  text-align: center;
}

.landing-page-options .option .logo{
  margin:2em;
  text-align: center;
  height:250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-options .option .click-select{
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom:-20px;
  display: none;
  left:0;
  right:0;
}

.landing-page-options .option:hover .click-select{
  display: block;
}

.judge-portal-container{
  display: flex;
  justify-content: center;
  margin:1em;
}

.judge-portal-container .judge-portal{
  min-height: 400px;
  min-width: 350px;
  max-width: 1024px;
  background-color: #fafafa;
  padding:1.5em;
  border-radius: 8px;
}

.my-contest-list{

}
.my-contest-list .contest{
  padding:1em;
  margin:1em;
  border-radius: 4px;
  border:1px solid #aaa;
  transition: ease-in-out 500ms;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.my-contest-list .contest:hover{
  transition: ease-in-out 1500ms;
  box-shadow:2px 2px 5px #aaa;
}

.contest .contest-state{
  width:70px;
  font-size: 1.0em;
  font-weight: bolder;
}

.contest .contest-title{
  font-size: 1.34em;
  font-weight: bolder;
  text-overflow: ellipsis;
}

.contest .contest-url{
  cursor: pointer;
  padding:.4em 1.4em;
  border-radius: .4em;
  transition: ease-in-out 1500ms;
}

.contest .contest-url:hover{
  cursor: pointer;
}

/* .contest:hover .contest-url{
  transition: ease-in-out 300ms;
  transform: scale(1.13);
} */

.alert{
  padding:.25em 1em;
  border-radius: .4em;
}

.click{
  cursor: pointer;
  transition: ease-in-out 500ms;
}

.click:hover{
  transform: scale(1.05);
}

.judge-portal-container .sticky{
  margin:0 -1em;
  padding:.5em 0;
  background-color: #fafafa;
}