@import-normalize;

h1, h2, h3{
  margin-top: .2em;
  margin-bottom: .5em;
}
.head-room{
    margin-top:16px;
  }
  
  .head-room-xl{
    margin-top:64px;
  }
  .head-room-l{
    margin-top:32px;
  }
  i.fa, i.fad, i.fal, i.fas {
    padding-left:.3em;
    padding-right:.3em;
  }
  
body input[type='checkbox']{
    margin-right:1em;
  }

  .flex-between{
    display:flex;
    justify-content: space-between;
  }
  .flex-between.center{
    align-items: center;
  }

  .flex-center{
      display:flex;
      justify-content: center;
  }

  .flex{
      display:flex;
  }

  .flex.wrap, .flex-between.wrap, .flex-center.wrap{
      flex-wrap: wrap;
  }

  #fms-shim{
      position: fixed;
      bottom:0;
      right:-20px;
      width:10px;
      height:10px;
  }

  